<script setup lang="ts">
import {
	FormControl,
	FormItem,
	FormLabel,
	FormField,
	FormMessage,
	Input,
} from '@laam/ui/base';
import PhoneCodeSelector from '../checkout/PhoneCodeSelector.vue';
import type { AddressFormValuesSchema } from '~/components/address-book/types.ts';
import type { Country } from '~/components/checkout/types.ts';
import countries from '~/utils/countries.json';
import { useLoganStore } from '~/stores/logan';
import { PhX } from '@phosphor-icons/vue';
import { sanitizeInput } from '~/utils/checkout';
import { loganPhRegexString } from '~/utils/enums';

interface PhoneInput {
	error: boolean;
	disableCountrySelector: boolean;
	disabled: boolean;
}
withDefaults(defineProps<PhoneInput>(), {
	error: false,
	disableCountrySelector: true,
	disabled: false,
});
const Loganstore = useLoganStore();
const values: AddressFormValuesSchema = inject('values')!;
const phNumberPlaceholder = '03xxxxxxxxx';
const setValues: (values: object, shouldValidate: boolean) => void =
	inject('setValues')!;

const setPhoneFieldValues = (countryCode: string) => {
	if (countryCode) {
		const country: Country = countries[countryCode as keyof typeof countries];
		if (country) {
			setValues(
				{
					phonePlaceholderPattern: phNumberPlaceholder ?? 'Enter phone number',
					phoneRegexPattern: loganPhRegexString ?? '',
					phonePrefix: country!.phone[0]!.toString(),
				},
				false,
			);
		}
	}
};

watch(
	() => values.phoneCountryCode,
	(newPhoneCountryCode) => {
		setPhoneFieldValues(newPhoneCountryCode!);
	},
);

onMounted(() => {
	setPhoneFieldValues(values.phoneCountryCode!);
});
</script>
<template>
	<FormField
		v-slot="{ componentField, errors, handleReset }"
		name="phoneNumber"
		class="w-full"
	>
		<FormItem class="w-full">
			<div class="phone_input_container gap-sm flex flex-col w-full">
				<FormLabel class="text-sm font-medium text-gray-700">
					Phone Number
				</FormLabel>
				<FormControl>
					<div class="flex items-center justify-start w-full">
						<div class="flex-[1]">
							<PhoneCodeSelector
								v-if="!disableCountrySelector"
								:disabled="disabled"
							/>
							<div
								v-else
								class="p-lg gap-md rounded-medium border-r-transparent flex items-center justify-between border border-gray-300 border-solid rounded-r-none"
							>
								<div
									class="gap-md text-md flex items-center text-gray-900"
									data-testid="phone-country-code"
								>
									{{ values.phoneCountryCode }}
								</div>
							</div>
						</div>
						<div class="w-full flex-[9]">
							<Input
								:type="'tel'"
								:placeholder="values.phonePlaceholderPattern"
								v-bind="componentField"
								class="border-l-gray-300 logan_phone_input border-l rounded-l-none"
								:disabled="Loganstore.showOtpInputBox"
								:class="{
									'border-error-300 focus-within:ring-0': errors.length !== 0,
								}"
								@keypress="sanitizeInput"
							/>
						</div>
						<div
							data-testid="resetButton"
							class="absolute right-[9%] cursor-pointer reset-button"
							@click="handleReset"
						>
							<PhX
								v-if="
									componentField.modelValue?.length > 0 &&
									!Loganstore.showOtpInputBox
								"
								:size="16"
								:color="'#A3A3A3'"
							/>
						</div>
					</div>
					<FormMessage class="logan_phone_error" />
				</FormControl>
			</div>
		</FormItem>
	</FormField>
</template>
